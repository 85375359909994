<template>
  <v-row>
    <v-col cols="12" lg="12" md="12" sm="12">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="value"
            small
            prepend-icon="mdi-calendar-range"
            readonly
            v-bind="attrs"
            v-on="on"
            persistent-hint
            :label="label"
          ></v-text-field>
        </template>
        <v-date-picker
          no-title
          scrollable
          :value="value"
          @input="input($event)"
          :max="maxDate"
          :min="minDate"
          :ref="yearPicker ? 'picker' : null"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    value: { type: String },
    label: { type: String },
    maxDate: { type: String, required: false },
    minDate: { type: String, required: false },
    yearPicker: { type: Boolean, required: false },
  },

  data() {
    return {
      menu: false,
    };
  },

  methods: {
    input(event) {
      this.menu = false;
      this.$emit("input", event);
    },
  },
  created() {},
  watch: {
    menu(val) {
      this.yearPicker
        ? val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"))
        : "";
    },
  },
};
</script>
